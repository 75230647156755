//CSSをバンドル
import 'Src/scss/schoolTop.scss'
//共通のJavaScriptを読み込み
import 'Src/js/utils/utils'


import Swiper from 'swiper/bundle';
// 全てのスタイルをまとめて
import 'swiper/css/bundle';
// Swiper を初期化（後述）
const swiper = new Swiper('.swiper', {
  loop: true,
  autoplay: {
    delay: 5000,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true //この行を追記する
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
  // scrollbar: {
  //   el: '.swiper-scrollbar',
  // },
})
